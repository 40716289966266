import React from 'react'
import Layout from '../components/layout'
import { graphql, StaticQuery } from 'gatsby'
import { Link } from 'gatsby'
import styled from 'styled-components'
import Footer from '../components/footer'

const PageNumberWrapper = styled.div`
	background: ${(props) => (props.isCurrentPage ? '#eee' : 'white')};
`

const BlogItems = ({ pageContext }) => (
	<StaticQuery
		query={graphql`
			{
				allWordpressAcfPosts(limit: 30) {
					edges {
						node {
							acf {
								featured_image {
									source_url
								}
							}
						}
					}
				}
			}
		`}
		render={(props) => (
			<Layout>
				<div className='blog-list-body'>
					<section className='hero is-fullheight'>
						<div className='container column is-6'>
							<div className='pagination'>
								{Array.from({ length: pageContext.numberOfPages }).map(
									(page, index) => (
										<PageNumberWrapper
											className='page-number-wrapper'
											key={index}
											isCurrentPage={index + 1 === pageContext.currentPage}>
											<Link
												className='page-number'
												to={index === 0 ? '/blog/' : `/blog/${index + 1}`}>
												{index + 1}
											</Link>
										</PageNumberWrapper>
									)
								)}
							</div>
							{pageContext.posts.map((post) => (
								<div key={post.node.wordpress_id} className='card'>
									<h1 dangerouslySetInnerHTML={{ __html: post.node.title }} />
									<img
										src={post.node.acf.featured_image.source_url}
										className='blog-list-image'
										alt='Blog List Feature'
									/>
									<div className='blog-list-date'>{post.node.date}</div>
									<h3 dangerouslySetInnerHTML={{ __html: post.node.excerpt }} />
									<div className='column is-4-desktop is-offset-4-desktop is-12-mobile'>
										<div className='blog-btn'>
											<Link
												to={`/post/${post.node.slug}`}
												className='blog-post-btn'>
												Read More
											</Link>
										</div>
									</div>
								</div>
							))}
							<div className='pagination'>
								{Array.from({ length: pageContext.numberOfPages }).map(
									(page, index) => (
										<PageNumberWrapper
											className='page-number-wrapper'
											key={index}
											isCurrentPage={index + 1 === pageContext.currentPage}>
											<Link
												className='page-number'
												to={index === 0 ? '/blog/' : `/blog/${index + 1}`}>
												{index + 1}
											</Link>
										</PageNumberWrapper>
									)
								)}
							</div>
						</div>
					</section>
				</div>
				<Footer />
			</Layout>
		)}
	/>
)

export default BlogItems
